import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DataClientModel } from 'src/app/features/models';
import { ClientSearchService, ContentfulService, LoadingService } from 'src/app/shared/services';
import { HttpClient } from '@angular/common/http';
import { CurrencyPipeCustom, monthNameEnglishToSpanish } from '../../../../../../../src/app/shared/pipes';
import * as utils from '../../../../../../../src/app/shared/helpers/utils';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ClientRequestService, ClientService } from 'src/app/features/services';
import { environment } from '@environment';
import { InputLog } from 'src/app/features/models/log/InputLog';
import { TracesService } from 'src/app/core/services/traces/traces.service';
import { Status } from 'src/app/features/models/log/status';
import 'moment-timezone';

@Component({
  selector: 'app-balance-certificate',
  templateUrl: './balance-certificate.component.html',
  styleUrls: ['./balance-certificate.component.scss']
})
export class BalanceCertificateComponent implements OnInit {
  lineaDeNegocioLista: any[] = [
    { label: 'Selecciona', value: 'Selecciona' },
    { label: 'Corredora de Bolsa', value: 'cb' },
    { label: 'Seguros de Vida', value: 'vd' },
  ];
  lineaDeNegocioListaSaldosFallecimiento: any[] = [
    { label: 'Corredora de Bolsa', value: 'CB' },
    { label: 'AGF', value: 'AGF' },
  ];
  lineaNegocioSelect = new FormControl(this.lineaDeNegocioLista[0].label, [Validators.required]);
  lineaNegocioSelectMes = new FormControl(this.lineaDeNegocioLista[0].label, [Validators.required]);
  lineaNegocioSelectSaldoFallecimiento = new FormControl(this.lineaDeNegocioListaSaldosFallecimiento[0].label, [Validators.required]);
  dataClient: DataClientModel;
  hasCoreError = false;
  showHasSelectError = false;
  showHasSelectErrorCertSaldFallecimiento = false;
  showHasSelectErrorMes = false;
  errorMessage = '';
  products: any;
  productsCb: any = [];
  fechaDefuncion: any; 
  productsVida: any = [];
  formMes: FormGroup;
  formSaldosPorFallecimiento: FormGroup;
  productsMes: any = [];
  mensajeErrorDateMes: string = '';
  showHasErrorDateMes: boolean = false;
  showHasErrorDateCertificadoSaldos: boolean = false;
  showMonthlyCertificate: boolean = false;
  zoom: string = '0.65';
  showCertificadoPorFallecimiento: boolean = true;
  isClienteFallecido: boolean = false;
  isCertificadoFallecimientoDisponible: boolean = false;
  clienteFallecidoSinFecha: boolean;
  buttonStyle: string = 'outline-grey';
  constructor(
    private loader: LoadingService,
    private http: HttpClient,
    private clientService: ClientService,
    private formBuilder: FormBuilder,
    private clientRequest: ClientRequestService,
    private contentfulService: ContentfulService,
    private tracesService: TracesService,
    private clientSearchService: ClientSearchService
  ) { 
    this.formMes = this.formBuilder.group({
      periodo: [moment().clone(), []],
      linea: [null, []]
    });    
  }

  async ngOnInit() {
    this.loader.showLoading();
    let data = sessionStorage.getItem('clientData');
    this.setFechaCtrlComprFallecimiento();
    this.dataClient = JSON.parse(data);
    this.products = JSON.parse(sessionStorage.getItem("balanceCertificate"));
    await this.loadProductsData();
    await this.loadVisibilityMonth();
    const fechaDefuncion = this.dataClient?.FechaDefuncion;
    this.clienteFallecidoSinFecha = !fechaDefuncion;
    this.fechaDefuncion = (!fechaDefuncion) ? `Sin Información` : this.dataClient?.FechaDefuncion.replace(/-/g, '/');
    this.isClienteFallecido = (String(this.dataClient?.IdTipoBloqueo).toLocaleLowerCase() === 'bmuert');
    await this.isEnableCertificadoPorFallecimiento();
    await this.validateAGF(this.dataClient?.rut.replace(/\./g, ''));
    this.showCertificadoPorFallecimiento = (this.isClienteFallecido) ? true : false;    
    this.formSaldosPorFallecimiento.controls['periodo'].setValue('Sin Información');
    const esFechaValida = (fecha) => !isNaN(Date.parse(fecha)) && /^\d{4}\/\d{2}\/\d{2}$/.test(fecha);
    if(esFechaValida(this.fechaDefuncion)){
      this.fechaDefuncion = moment.tz(new Date(this.fechaDefuncion), 'America/Santiago');
      this.formSaldosPorFallecimiento.controls['periodo'].setValue(this.fechaDefuncion.format('DD/MM/yyyy'));
      this.isCertificadoFallecimientoDisponible = this.fechaDefuncion > moment.tz(new Date('09/01/2020'), 'America/Santiago');
      this.lineaNegocioSelectSaldoFallecimiento = new FormControl(this.lineaDeNegocioListaSaldosFallecimiento[0].value, [Validators.required]);
    }
    this.buttonStyle = (this.clienteFallecidoSinFecha || !this.isCertificadoFallecimientoDisponible) ? 'disabled' : 'outline-grey';
    this.loader.hideLoading();
  }

  async isEnableCertificadoPorFallecimiento(){
    const entryID = environment.KEYS.CONTENTFUL.HABILITAR_SALDO_POR_FALLECIMIENTO;
    const {environmentList} = await this.contentfulService.getEntryFromFront(entryID);
    const {blacklist, zoom} = environmentList;
    const ambienteActual =  environment.name;
    const isBlacklist = blacklist.some( b => b === ambienteActual);
    this.zoom = zoom;
    if(isBlacklist){
      this.showCertificadoPorFallecimiento = false;
    }
  }

  async validateAGF(rut) {
    const isClientAGF = await this.clientSearchService.validateClientAGF(rut, undefined);    
    if (!isClientAGF){
      this.lineaDeNegocioListaSaldosFallecimiento = this.lineaDeNegocioListaSaldosFallecimiento.slice(0,1)
    }
  }

  setFechaCtrlComprFallecimiento() {
    this.formSaldosPorFallecimiento = this.formBuilder.group({
      periodo: [null, []],
      linea: [null, []]
    });
  }

  async loadProductsData() {
    this.products.forEach(async (product) => {
      if (product.amount > 0) {
        if (product.type === "CORREDOR_BOLSA" || product.type === "FFMM") {
          this.productsCb.push(product);
        } else if (product.type === "INSURANCES"){
          const params = {
            rut: this.dataClient.rut.replace(/\./g, ''),
            type: "VIDA",
            poliza: product.Poliza,
            tipoProducto: "NO",
            fechai: moment(new Date()).format('DD/MM/YYYY'),
            fechaf: moment(new Date()).format('DD/MM/YYYY'),
            typeInversion: "",
            typeAccount: "",
            operacion: null,
            categoria: null,
            nombreCliente: this.dataClient.name
          };          
          const productsDetail = await this.clientService.getProductDetail(params);
          product.name = productsDetail.descripcion;
          product.estado = productsDetail.info.estadoPoliza;
          product.inicioVigencia = this.setFormatDate(productsDetail.info.fechaInicioVigencia); 
          this.productsVida.push(product);  
        }
      }
    });    
  }

  async loadVisibilityMonth() {
    const ruteroID = environment.KEYS.CMSRUTERO.CERTIFICADO_SALDOS_MENSUAL;
    const dataRutero = await this.clientRequest.getRutero(ruteroID);
    let visibleRutero = true;
    if(dataRutero[0].active) {
      if(dataRutero[0].ruteroArr.includes(this.dataClient.rut.replace(/\./g, ''))) {
        visibleRutero = true;
      } else {
        visibleRutero = false;
      }
    }

    const entryID = environment.KEYS.CONTENTFUL.CERTIFICADO_SALDOS_MENSUAL;
    const dataContent = await this.contentfulService.getEntryFromFront(entryID);
    const visibleContentful = dataContent.enabled && dataContent.environmentList.environments.includes(environment.name);

    this.showMonthlyCertificate = visibleRutero && visibleContentful;
  }

  setFormatDate(dateHour: string) {
    const date = new Date(dateHour);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const joined = [day, month, year].join('/');
    return joined;
  }

  async clickSearchCertificate() {
    const objLog: any = {
      categoria: "Certificado de Saldos",
      operacion: "Certificado de Saldos",
      eventoLog: "Descargar certificado de saldos al dia",
    }
    try{
      if (!this.descargable()) {
        this.hasCoreError = false;
        if (this.lineaNegocioSelect.value === this.lineaDeNegocioLista[0].label) {
          this.showHasSelectError = true;
        }
        return;
      } else {
        await this.exportAsPdf();
        this.logData(objLog);
      } 
    } catch(err){
      console.log(err);
      objLog.eventoLog = err;
      this.logData(objLog, true); 
    }

  }

  selectLineaNegocio(event: any) {
    this.lineaNegocioSelect = new FormControl(event.target.value, [Validators.required]);
    if (this.lineaNegocioSelect.value !== this.lineaDeNegocioLista[0].label) {
      this.showHasSelectError = false;
    }
    this.hasCoreError = false;
  }

  descargable() {
    this.errorMessage = '';
    if (this.lineaNegocioSelect.value !== this.lineaDeNegocioLista[0].label 
    && !this.showAlertProducts()) {
      return true;
    }
    return false;
  }

  showAlertProducts() {
    if(this.productsCb.length === 0 && this.productsVida.length === 0) {
      this.errorMessage = 'El cliente no cuenta con saldos en la línea de negocio seleccionada.';
      return true;
    }
    if(this.lineaNegocioSelect.value === this.lineaDeNegocioLista[1].label && this.productsCb.length === 0) {
      this.errorMessage = 'El cliente no cuenta con saldos en la línea de negocio seleccionada.';
      return true;
    }
    if(this.lineaNegocioSelect.value === this.lineaDeNegocioLista[2].label && this.productsVida.length === 0) {
      this.errorMessage = 'El cliente no cuenta con saldos en la línea de negocio seleccionada.';
      return true;
    }
    return false;
  }

  async exportAsPdf(): Promise<void> {
    const pdfFile = (this.lineaNegocioSelect.value === this.lineaDeNegocioLista[1].label) ? 'Cb' : 'Vida';
    const file = await this.http.get(`assets/PDF/balanceCertificate${pdfFile}.html`, { observe: 'response', responseType: 'text' }).toPromise();
    this.createBalanceCertificatePDF(file);
  }

  async createBalanceCertificatePDF(html) {
    const currencyPipeCustom = new CurrencyPipeCustom();
      const pdfLine = (this.lineaNegocioSelect.value === this.lineaDeNegocioLista[1].label) ? 'Corredora' : 'Vida';
      const filename = `CertificadoDeSaldos_${this.dataClient.rut.replace(/[\.\-]/g, '')}_${pdfLine}.pdf`;
      
      const now = new Date();
      const longDate = moment(now).locale('es-es').format('LL') + " " + moment(now).format('HH:mm') + " hrs.";
      const downloadDate = moment(now).format('DD/MM/YYYY');
      const css = 'assets/style.css?v=1.0';
      const logo = 'assets/sura-logo.svg';
      const signature = (this.lineaNegocioSelect.value === this.lineaDeNegocioLista[1].label) ? 'assets/signature-balance-certificate-cb.jpg' : 'assets/signature-balance-certificate-vida.jpg';

      const options = {
          margin: 40,
          filename,
          jsPDF: {
              orientation: 'p',
              unit: 'px',
              hotfixes: ['px_scaling'],
              format: 'letter',
              putOnlyUsedFonts: true,
              floatPrecision: 16,
          },
          html2canvas: { scale: 2 },
      };

      let htmlPDF = html.body;
      htmlPDF = htmlPDF.split('[css]').join(css);
      htmlPDF = htmlPDF.split('[logo]').join(logo);
      htmlPDF = htmlPDF.split('[signature]').join(signature);
      htmlPDF = htmlPDF.split('[fecha]').join(longDate);
      htmlPDF = htmlPDF.split('[fechaDescarga]').join(downloadDate);
      htmlPDF = htmlPDF.split('[nombreCliente]').join(this.dataClient.name);
      htmlPDF = htmlPDF.split('[rutCliente]').join(this.dataClient.rut);

      if (this.lineaNegocioSelect.value === this.lineaDeNegocioLista[1].label) {
        let totalBalanceFinanciero = 0;
        let totalBalancePrevisional = 0; 
        let inversionFinancieraProducts = '';
        let ahorroPrevisionalProducts = '';

        this.productsCb.forEach((product) => {
          const amount = currencyPipeCustom.transform(Number(product.amount));
          if(product.prevision === "NOAPV") {
            totalBalanceFinanciero += product.amount;
            inversionFinancieraProducts += `
            <div class="row" style="font-family: 'Arial', sans-serif;margin-bottom: 1rem; height: 34px; border-top: 0.5px solid #E5E2E2; display: flex; align-items: center; justify-content: space-between; margin: 0;">
              <div class="cell">
                  <div class="label-detail" style="font-family: 'Arial', sans-serif;color: #444444; font-size: 12px; letter-spacing: 0; line-height: 14px;">
                      ${product.name}
                  </div>
              </div>
              <div class="cell">
              </div>
              <div class="cell">
                  <div class="txt-detail" style="font-family: 'Arial', sans-serif;color: #252323; font-size: 13px; font-weight: 500; letter-spacing: 0.09px; line-height: 19px;">
                      ${amount}
                  </div>
              </div>
            </div>`;
          } else if (product.prevision === "APV") {
            totalBalancePrevisional += product.amount;
            ahorroPrevisionalProducts += `
            <div class="row" style="font-family: 'Arial', sans-serif;margin-bottom: 1rem; height: 34px; border-top: 0.5px solid #E5E2E2; display: flex; align-items: center; justify-content: space-between; margin: 0;">
              <div class="cell">
                  <div class="label-detail" style="font-family: 'Arial', sans-serif;color: #444444; font-size: 12px; letter-spacing: 0; line-height: 14px;">
                      ${product.name}
                  </div>
              </div>
              <div class="cell">
              </div>
              <div class="cell">
                  <div class="txt-detail" style="font-family: 'Arial', sans-serif;color: #252323; font-size: 13px; font-weight: 500; letter-spacing: 0.09px; line-height: 19px;">
                      ${amount}
                  </div>
              </div>
            </div>`;
          }
        });

        const showInversionFinanciera = (inversionFinancieraProducts !== "") ? 'style="margin-bottom: 4rem;"' : 'style="margin-bottom: 4rem; display: none;"';
        const showAhorroPrevisional = (ahorroPrevisionalProducts !== "") ? 'style="margin-bottom: 4rem;"' : 'style="margin-bottom: 4rem; display: none;"';

        htmlPDF = htmlPDF.split('[showInversionFinanciera]').join(showInversionFinanciera);
        htmlPDF = htmlPDF.split('[rowsInversionFinanciera]').join(inversionFinancieraProducts);
        htmlPDF = htmlPDF.split('[totalInversionFinanciera]').join(currencyPipeCustom.transform(Number(totalBalanceFinanciero)));
        htmlPDF = htmlPDF.split('[showAhorroPrevisional]').join(showAhorroPrevisional);
        htmlPDF = htmlPDF.split('[rowsAhorroPrevisional]').join(ahorroPrevisionalProducts);
        htmlPDF = htmlPDF.split('[totalAhorroPrevisional]').join(currencyPipeCustom.transform(Number(totalBalancePrevisional)));
      } else {
        let vidaProducts = '';
        let totalVida = 0;
        this.productsVida.forEach((product) => {
          const amount = currencyPipeCustom.transform(Number(product.amount));
          totalVida += product.amount;
          vidaProducts += `
                <div class="row col-5" style="font-family: 'Arial', sans-serif; height: 50px; border-top: 0.5px solid #E5E2E2; display: grid; gap: 10px 16px; width: 100%; margin: 0; align-items: center; grid-template-columns: 1.5fr 1.2fr 1fr 1fr 1.1fr;">
                <div class="cell left">
                    <div class="txt-detail" style="font-family: 'Arial', sans-serif; color: #53565A; font-size: 13px; font-weight: 600; letter-spacing: 0.09px; line-height: 19px; text-align: left;">
                        ${product.name}
                    </div>
                </div>
                <div class="cell left">
                    <div class="label-detail" style="font-family: 'Arial', sans-serif; color: #53565A; font-size: 14px; letter-spacing: 0; line-height: 20px; text-align: left;">
                        <span class="bold" style="font-family: 'Arial', sans-serif; font-weight: bold;">${product.Poliza}</span>
                    </div>
                </div>
                <div class="cell center">
                    <div class="label-detail" style="font-family: 'Arial', sans-serif; color: #53565A; font-size: 14px; letter-spacing: 0; line-height: 20px; text-align: center;">
                      ${product.inicioVigencia}
                    </div>
                </div>
                <div class="cell center">
                    <div class="label-detail" style="font-family: 'Arial', sans-serif; color: #53565A; font-size: 14px; letter-spacing: 0; line-height: 20px; text-align: center;">
                      ${product.estado}
                    </div>
                </div>
                <div class="cell right">
                    <div class="label-detail" style="font-family: 'Arial', sans-serif; color: #53565A; font-size: 14px; letter-spacing: 0; line-height: 20px; text-align: right;">
                        ${amount}
                    </div>
                </div>
            </div>`;
        });
        htmlPDF = htmlPDF.split('[totalVida]').join(currencyPipeCustom.transform(Number(totalVida)));
        htmlPDF = htmlPDF.split('[rowsVida]').join(vidaProducts);
      }
      utils.convertToPDF(htmlPDF, options, true);
  }

  selectLineaNegocioMes(event: any) {
    this.lineaNegocioSelectMes = new FormControl(event.target.value, [Validators.required]);
    if (this.lineaNegocioSelectMes.value !== this.lineaDeNegocioLista[0].label) {
      this.showHasSelectErrorMes = false;
    }
    this.hasCoreError = false;
  }

  selectLineaNegocioSaldoFallecimiento(event: any) {
    this.lineaNegocioSelectSaldoFallecimiento = new FormControl(event.target.value, [Validators.required]);
    if (this.lineaNegocioSelectSaldoFallecimiento.value === this.lineaDeNegocioListaSaldosFallecimiento[0].label) {
      this.showHasSelectErrorCertSaldFallecimiento = true;
    }else{
      this.showHasSelectErrorCertSaldFallecimiento = false;
    }
  }

  async descargarSaldosPorFallecimiento() {
    
    const objLog: any = {
      categoria: "Certificado de Saldos",
      operacion: "Certificado de Saldos",
      eventoLog : "botón descargar certificado de fallecimiento"
    }

    try{
      
      this.errorMessage = '';

      if (this.clienteFallecidoSinFecha || !this.isCertificadoFallecimientoDisponible) return;

      this.hasCoreError = false;
      this.loader.showLoading();
      const fecha = this.fechaDefuncion.format('yyyyMMDD');
      const opcionesFechaEnPalabras: any = { year: 'numeric', month: 'long', day: 'numeric' }
      const fechaEnPalabras = new Date().toLocaleDateString('es-CL', opcionesFechaEnPalabras);
      const fechaFallecimientoEnPalabras = new Date( this.fechaDefuncion.format('yyyy/MM/DD'))
                                                   .toLocaleDateString('es-CL', opcionesFechaEnPalabras)
      let payload = 
        {
          rut: this.dataClient.rut.replace(/[\.]/g, ''),
          rutFormateado: this.dataClient.rut,
          fecha,
          lineaDeNegocio: this.lineaNegocioSelectSaldoFallecimiento.value,
          nombreCliente: this.dataClient.name,
          fechaEmisionCertificado: {fechaEnPalabras, fechaFallecimientoEnPalabras },
          fechaDefuncion: this.fechaDefuncion,
          segmento: this.dataClient.segment,
          zoom: this.zoom
        }  
      const {response} = await this.clientRequest.getDocumentoSaldosPorFallecimiento(payload);
      const certificado = response?.certificadoPorFallecimiento?.comprobante;
      const totales = response?.certificadoPorFallecimiento?.totales;
      const hayTotales = totales.some( t => t > 0 );

      if(!hayTotales){       
          this.errorMessage = `El cliente no tiene saldos en la fecha seleccionada.`;   
          return;
      }
      this.downloadFromBase64(certificado, 'certificado.pdf');
      this.logData(objLog, false, 1);
    } catch(error) {
      console.error(error);
      this.hasCoreError = true;
      objLog.eventoLog = error;
      this.logData(objLog, true, 1);
    } finally {
      this.loader.hideLoading();
    } 
  }

  downloadFromBase64(base64: string, fileName: string) {
    const linkSource = "data:application/pdf;base64," + base64;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  async descargarCertificadoMes() {
    this.mensajeErrorDateMes = "";
    this.errorMessage = "";
    const periodo = this.formMes.get('periodo').value as Moment;
    const hoy = new Date();
  
    if (periodo.toDate().getFullYear() >= 2013 && periodo.toDate().getFullYear() <= hoy.getFullYear()) {

      if(periodo.toDate().getFullYear() === hoy.getFullYear()) {

        if(hoy.getMonth() === 0) {
          if(periodo.toDate().getFullYear() < hoy.getFullYear()) {
            this.showHasErrorDateMes = false;
          } else {
            this.showHasErrorDateMes = true;
          }
        } else {
          if(periodo.toDate().getMonth() < hoy.getMonth()) {
            this.showHasErrorDateMes = false;
          } else {
            this.showHasErrorDateMes = true;
          }
        }

      } else {
        this.showHasErrorDateMes = false;
      }

    } else {
      this.showHasErrorDateMes = true;
    }

    if(this.showHasErrorDateMes) {
      const mesFinalHoy = (hoy.getMonth().toString().length === 1) ? `0${hoy.getMonth().toString()}` : hoy.getMonth().toString(); 
      this.mensajeErrorDateMes = "Debes seleccionar un período entre el 01/2013 y el ";
      if (hoy.getMonth() === 0) {
        const anio = hoy.getFullYear() -1;
        this.mensajeErrorDateMes += `12/${anio.toString()}`;
      } else {
        this.mensajeErrorDateMes += `${mesFinalHoy}/${hoy.getFullYear().toString()}`;
      }
    }

    if (this.lineaNegocioSelectMes.value === this.lineaDeNegocioLista[0].label) {
      this.showHasSelectErrorMes = true;
    } else {
      this.showHasSelectErrorMes = false;
    }

    if(!this.showHasErrorDateMes && !this.showHasSelectErrorMes) {
      let productsDetail;
      const mes = periodo.toDate().getMonth() + 1;
      const mesDescarga = periodo.toDate().getMonth();
      const mesFinal = (mes.toString().length === 1) ? `0${mes.toString()}` : mes.toString(); 
      const mesFinalDescarga = (mesDescarga.toString().length === 1) ? `0${mesDescarga.toString()}` : mesDescarga.toString(); 
      let rut = this.dataClient.rut.replace(/\./g, '');
      rut = rut.substring(0, rut.length - 2);

      // Va fijo el día, porque en el backend hay una función para calcular el último día de cada mes sin importar el día que vaya como parámetro
      const date = `${periodo.toDate().getFullYear()}${mesFinal}20`; 
      const dateDescarga = `${periodo.toDate().getFullYear()}${mesFinalDescarga}`; 
      
      const objLog: any = {
        categoria: "Certificado de Saldos",
        operacion: "Certificado de Saldos",
        eventoLog : "Descargar certificado de saldos por mes"
      }
      this.logData(objLog);

      try {
        this.loader.showLoading();
        const businessLine = (this.lineaNegocioSelectMes.value === this.lineaDeNegocioLista[2].label) ? 'vida' : 'cb';
        productsDetail = await this.clientService.getCertificateMonth(rut, date, businessLine);
        this.loader.hideLoading();

        if(this.lineaNegocioSelectMes.value === this.lineaDeNegocioLista[1].label) {
          if (productsDetail.lSalidaFFMM.length === 0 && 
            productsDetail.lSalidaINV.length === 0 && 
            productsDetail.lSalidaPershing.length === 0) {
            this.errorMessage = 'El cliente no cuenta con saldos en el período y línea de negocio seleccionados.';
          } else {
            await this.exportAsPdfMes(productsDetail, dateDescarga);
          }
        }

        if (this.lineaNegocioSelectMes.value === this.lineaDeNegocioLista[2].label) {
          if (productsDetail.lSalidaVida.length === 0) {
            this.errorMessage = 'El cliente no cuenta con saldos en el período y línea de negocio seleccionados.';
          } else {
            await this.exportAsPdfMes(productsDetail, dateDescarga);
          }
        }
      } catch(err) {
        this.errorMessage = 'Ocurrió un error al obtener los datos.';
        this.loader.hideLoading();
        console.log(err);
        objLog.eventoLog = err;
        this.logData(objLog, true);
      }
    }     
  }

  async exportAsPdfMes(productsDetail: any, date: string): Promise<void> {
    const pdfFile = (this.lineaNegocioSelectMes.value === this.lineaDeNegocioLista[1].label) ? 'Cb' : 'Vida';
    const file = await this.http.get(`assets/PDF/balanceCertificate${pdfFile}Month.html`, { observe: 'response', responseType: 'text' }).toPromise();
    await this.createBalanceCertificatePDFMes(file, productsDetail, date);
  }

  async createBalanceCertificatePDFMes(html, productsDetail, date) {
    const periodo = this.formMes.get('periodo').value as Moment;
    const mes = periodo.toDate().getMonth() + 1;
    const mesFinal = (mes.toString().length === 1) ? `0${mes.toString()}` : mes.toString(); 
    const monthYear = `${mesFinal}_${periodo.toDate().getFullYear()}`; 

    const year = date.substring(0,4);
    let month = date.substring(4,6);
    month = month[0] === "0" ? month[1] : month;
    const day = new Date(Number(year), Number(month) + 1, 0).getDate();
    const months = ["enero","febrero","marzo","abril","mayo","junio","julio","agosto","septiembre","octubre","noviembre","diciembre"];
    
    const downloadDateMonth = `${day} de ${months[month]} de ${year}`;
    
    const currencyPipeCustom = new CurrencyPipeCustom();
    const pdfLine = (this.lineaNegocioSelectMes.value === this.lineaDeNegocioLista[1].label) ? 'Corredora' : 'Vida';
    const filename = `CertificadoDeSaldos_${this.dataClient.rut.replace(/[\.\-]/g, '')}_${pdfLine}_${monthYear}.pdf`;
    
    const now = new Date();
    const longDate = moment(now).locale('es-es').format('LL') + " " + moment(now).format('HH:mm') + " hrs.";
    const downloadDate = moment(now).format('DD/MM/YYYY');
    const css = 'assets/style.css?v=1.0';
    const logo = 'assets/sura-logo.svg';
    const signature = (this.lineaNegocioSelectMes.value === this.lineaDeNegocioLista[1].label) ? 'assets/signature-balance-certificate-cb.jpg' : 'assets/signature-balance-certificate-vida.jpg';

    const options = {
        margin: 40,
        filename,
        jsPDF: {
            orientation: 'p',
            unit: 'px',
            hotfixes: ['px_scaling'],
            format: 'letter',
            putOnlyUsedFonts: true,
            floatPrecision: 16,
        },
        html2canvas: { scale: 2 },
    };

    let htmlPDF = html.body;
    htmlPDF = htmlPDF.split('[css]').join(css);
    htmlPDF = htmlPDF.split('[logo]').join(logo);
    htmlPDF = htmlPDF.split('[signature]').join(signature);
    htmlPDF = htmlPDF.split('[fecha]').join(longDate);
    htmlPDF = htmlPDF.split('[fechaDescarga]').join(downloadDate);
    htmlPDF = htmlPDF.split('[fechaDescargaMes]').join(downloadDateMonth);
    htmlPDF = htmlPDF.split('[nombreCliente]').join(this.dataClient.name);
    htmlPDF = htmlPDF.split('[rutCliente]').join(this.dataClient.rut);

    if (this.lineaNegocioSelectMes.value === this.lineaDeNegocioLista[1].label) {
      // FFMM
      let totalFFMM = 0; 
      let rowsFFMM = '';
      productsDetail.lSalidaFFMM.forEach((product) => {
        const amount = currencyPipeCustom.transform(Number(product.saldoPesos));
        const valorCuotas = currencyPipeCustom.transform(Number(product.valorcuotas));
        totalFFMM += product.saldoPesos;
        rowsFFMM += `
          <div class="row col-7" style="font-family: 'Arial', sans-serif; height: 50px; border-top: 0.5px solid #E5E2E2; display: grid; gap: 10px 16px; width: 100%; margin: 0; align-items: center; grid-template-columns: 1.5fr 1fr 1fr 1fr 1.2fr 1.2fr 1fr;">
            <div class="cell left">
                <div class="txt-detail" style="font-family: 'Arial', sans-serif; color: #53565A; font-size: 11px; font-weight: 600; letter-spacing: 0.09px; line-height: 120%; text-align: left;">
                  ${product.fondoSerie}
                </div>
            </div>
            <div class="cell center">
                <div class="label-detail" style="font-family: 'Arial', sans-serif; color: #53565A; font-size: 11px; letter-spacing: 0; line-height: 120%; text-align: center;">
                  ${product.serie}
                </div>
            </div>
            <div class="cell center">
                <div class="label-detail" style="font-family: 'Arial', sans-serif; color: #53565A; font-size: 11px; letter-spacing: 0; line-height: 120%; text-align: center;">
                  ${product.tipoCuenta}
                </div>
            </div>
            <div class="cell left">
                <div class="label-detail" style="font-family: 'Arial', sans-serif; color: #53565A; font-size: 11px; letter-spacing: 0; line-height: 120%; text-align: left;">
                  ${product.saldoCuotas}
                </div>
            </div>
            <div class="cell left">
                <div class="label-detail" style="font-family: 'Arial', sans-serif; color: #53565A; font-size: 11px; letter-spacing: 0; line-height: 120%; text-align: left;">
                  ${valorCuotas}
                </div>
            </div>
            <div class="cell left">
                <div class="label-detail" style="font-family: 'Arial', sans-serif; color: #53565A; font-size: 11px; letter-spacing: 0; line-height: 120%; text-align: left;">
                ${product.fechavalorcuota.substring(6, 8)}/${product.fechavalorcuota.substring(4, 6)}/${product.fechavalorcuota.substring(0, 4)}
                </div>
            </div>
            <div class="cell right">
                <div class="label-detail" style="font-family: 'Arial', sans-serif; color: #53565A; font-size: 11px; letter-spacing: 0; line-height: 120%; text-align: right;">
                  ${amount}
                </div>
            </div>
          </div>`;
      });

      const showFFMM = (rowsFFMM !== "") ? 'style="margin-bottom: 3rem;"' : 'style="margin-bottom: 3rem; display: none;"';

      htmlPDF = htmlPDF.split('[showFFMM]').join(showFFMM);
      htmlPDF = htmlPDF.split('[rowsFFMM]').join(rowsFFMM);
      htmlPDF = htmlPDF.split('[totalFFMM]').join(currencyPipeCustom.transform(Number(totalFFMM)));

      // Cuentas de Inversión
      let totalInv = 0; 
      let rowsInv = '';
      productsDetail.lSalidaINV.forEach((product) => {
        const amount = currencyPipeCustom.transform(Number(product.saldo));
        totalInv += product.saldo;
        rowsInv += `
            <div class="row col-6" style="font-family: 'Arial', sans-serif; height: 50px; border-top: 0.5px solid #E5E2E2; display: grid; gap: 10px 16px; width: 100%; margin: 0; align-items: center; grid-template-columns: 1.5fr 1fr 1fr 1fr 1.2fr 1fr;">
              <div class="cell left">
                  <div class="txt-detail" style="font-family: 'Arial', sans-serif; color: #53565A; font-size: 11px; font-weight: 600; letter-spacing: 0.09px; line-height: 120%; text-align: left;">
                    ${product.nombre}
                  </div>
              </div>
              <div class="cell center">
                  <div class="label-detail" style="font-family: 'Arial', sans-serif; color: #53565A; font-size: 11px; letter-spacing: 0; line-height: 120%; text-align: center;">
                  
                  </div>
              </div>
              <div class="cell center">
                  <div class="label-detail" style="font-family: 'Arial', sans-serif; color: #53565A; font-size: 11px; letter-spacing: 0; line-height: 120%; text-align: center;">
                    
                  </div>
              </div>
              <div class="cell center">
                  <div class="label-detail" style="font-family: 'Arial', sans-serif; color: #53565A; font-size: 11px; letter-spacing: 0; line-height: 120%; text-align: center;">
                    ${product.regimen}
                  </div>
              </div>
              <div class="cell center">
                  <div class="label-detail" style="font-family: 'Arial', sans-serif; color: #53565A; font-size: 11px; letter-spacing: 0; line-height: 120%; text-align: center;">
                    ${product.tipoRegimen}
                  </div>
              </div>
              <div class="cell right">
                  <div class="label-detail" style="font-family: 'Arial', sans-serif; color: #53565A; font-size: 11px; letter-spacing: 0; line-height: 120%; text-align: right;">
                    ${amount}
                  </div>
              </div>
            </div>`;
      });

      const showInv = (rowsInv !== "") ? 'style="margin-bottom: 3rem;"' : 'style="margin-bottom: 3rem; display: none;"';

      htmlPDF = htmlPDF.split('[showInv]').join(showInv);
      htmlPDF = htmlPDF.split('[rowsInv]').join(rowsInv);
      htmlPDF = htmlPDF.split('[totalInv]').join(currencyPipeCustom.transform(Number(totalInv)));
      
      // Pershing
      let totalPer = 0; 
      let totalPerUSD = 0; 
      let rowsPer = '';
      productsDetail.lSalidaPershing.forEach((product) => {
        const amount = currencyPipeCustom.transform(Number(product.saldoCLP));
        const precio = currencyPipeCustom.transform(Number(product.precio));
        const valorDolar = currencyPipeCustom.transform(Number(product.tipoCambio));
        const saldoUSD = parseFloat(product.saldoUSD);
        totalPer += product.saldoCLP;
        totalPerUSD += saldoUSD;
        rowsPer += `
            <div class="row col-6" style="font-family: 'Arial', sans-serif; height: 50px; border-top: 0.5px solid #E5E2E2; display: grid; gap: 10px 16px; width: 100%; margin: 0; align-items: center; grid-template-columns: 1.5fr 1fr 1fr 1fr 1.2fr 1fr;">
              <div class="cell left">
                  <div class="txt-detail" style="font-family: 'Arial', sans-serif; color: #53565A; font-size: 11px; font-weight: 600; letter-spacing: 0.09px; line-height: 120%; text-align: left;">
                    ${product.fondo}
                  </div>
              </div>
              <div class="cell center">
                  <div class="label-detail" style="font-family: 'Arial', sans-serif; color: #53565A; font-size: 11px; letter-spacing: 0; line-height: 120%; text-align: center;">
                      -
                  </div>
              </div>
              <div class="cell center">
                  <div class="label-detail" style="font-family: 'Arial', sans-serif; color: #53565A; font-size: 11px; letter-spacing: 0; line-height: 120%; text-align: center;">
                    ${product.cantidad}
                  </div>
              </div>
              <div class="cell left">
                  <div class="label-detail" style="font-family: 'Arial', sans-serif; color: #53565A; font-size: 11px; letter-spacing: 0; line-height: 120%; text-align: left;">
                    ${precio}
                  </div>
              </div>
              <div class="cell left">
                  <div class="label-detail" style="font-family: 'Arial', sans-serif; color: #53565A; font-size: 11px; letter-spacing: 0; line-height: 120%; text-align: left;">
                    ${valorDolar}
                  </div>
              </div>
              <div class="cell right">
                  <div class="label-detail" style="font-family: 'Arial', sans-serif; color: #53565A; font-size: 11px; letter-spacing: 0; line-height: 120%; text-align: right;">
                    ${amount} <br>
                      USD ${saldoUSD.toFixed(5)}
                  </div>
              </div>
            </div>`;
      });

      const showPer = (rowsPer !== "") ? 'style="margin-bottom: 3rem;"' : 'style="margin-bottom: 3rem; display: none;"';

      htmlPDF = htmlPDF.split('[showPer]').join(showPer);
      htmlPDF = htmlPDF.split('[rowsPer]').join(rowsPer);
      htmlPDF = htmlPDF.split('[totalPer]').join(currencyPipeCustom.transform(Number(totalPer)));
      htmlPDF = htmlPDF.split('[totalPerUSD]').join(totalPerUSD.toFixed(5));
    } else {
      let rowsVida = '';
      let totalVida = 0;
      let totalVidaUF = 0;

      productsDetail.lSalidaVida.forEach((product) => {
        const amount = currencyPipeCustom.transform(Number(product.pesos));
        const vigencia = (product.vigencia === "V") ? "Vigente" : "No vigente";
        const uf = parseFloat(product.uf);
        totalVida += product.pesos;
        totalVidaUF += uf;

        rowsVida += `
        <div class="row col-8" style="font-family: 'Arial', sans-serif; height: 50px; border-top: 0.5px solid #E5E2E2; display: grid; gap: 10px 16px; width: 100%; margin: 0; align-items: center; grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1.2fr;">
          <div class="cell left">
              <div class="txt-detail" style="font-family: 'Arial', sans-serif; color: #53565A; font-size: 11px; font-weight: 600; letter-spacing: 0.09px; line-height: 120%; text-align: left;">
                  Seguro Ahorro Patrimonial${product.nombreProducto}
              </div>
          </div>
          <div class="cell left">
              <div class="label-detail" style="font-family: 'Arial', sans-serif; color: #53565A; font-size: 11px; letter-spacing: 0; line-height: 120%; text-align: left;">
                  <span class="bold" style="font-family: 'Arial', sans-serif; font-weight: bold;">${product.poliza}</span>
              </div>
          </div>
          <div class="cell center">
              <div class="label-detail" style="font-family: 'Arial', sans-serif; color: #53565A; font-size: 11px; letter-spacing: 0; line-height: 120%; text-align: center;">
                ${vigencia}
              </div>
          </div>
          <div class="cell left">
              <div class="label-detail" style="font-family: 'Arial', sans-serif; color: #53565A; font-size: 11px; letter-spacing: 0; line-height: 120%; text-align: left;">
                  ${product.fechaInicioVigencia.substring(6, 8)}/${product.fechaInicioVigencia.substring(4, 6)}/${product.fechaInicioVigencia.substring(0, 4)}
              </div>
          </div>
          <div class="cell left">
              <div class="label-detail" style="font-family: 'Arial', sans-serif; color: #53565A; font-size: 11px; letter-spacing: 0; line-height: 120%; text-align: left;">
              ${product.fechaVigencia.substring(6, 8)}/${product.fechaVigencia.substring(4, 6)}/${product.fechaVigencia.substring(0, 4)}
              </div>
          </div>
          <div class="cell left">
              <div class="label-detail" style="font-family: 'Arial', sans-serif; color: #53565A; font-size: 11px; letter-spacing: 0; line-height: 120%; text-align: left;">
                  -
              </div>
          </div>
          <div class="cell left">
              <div class="label-detail" style="font-family: 'Arial', sans-serif; color: #53565A; font-size: 11px; letter-spacing: 0; line-height: 120%; text-align: left;">
                  -
              </div>
          </div>
          <div class="cell right">
              <div class="label-detail" style="font-family: 'Arial', sans-serif; color: #53565A; font-size: 11px; letter-spacing: 0; line-height: 120%; text-align: right;">
                  ${amount} <br>
                  UF ${uf.toFixed(5)}
              </div>
          </div>
        </div>`;
      });
      htmlPDF = htmlPDF.split('[totalVida]').join(currencyPipeCustom.transform(Number(totalVida)));
      htmlPDF = htmlPDF.split('[totalVidaUF]').join(totalVidaUF.toFixed(5));
      htmlPDF = htmlPDF.split('[rowsVida]').join(rowsVida);
    }
    utils.convertToPDF(htmlPDF, options, true);
  }

  async logData(log: any, error?: boolean, secuencia = 0){
    const logChannel = "NWSA_ASESOR";
    let inputLog: InputLog = new InputLog();
    inputLog.DetalleAccionExtra = {};
    inputLog.Categoria = log.categoria;
    inputLog.Operacion = log.operacion;
    inputLog.eventoLog = log.eventoLog;
    inputLog.Metodo = "";
    inputLog.status = error ? Status.NOK : Status.OK;
    inputLog.EntradaLog = {
      rut: sessionStorage.getItem("rut"),
    };
    inputLog.Secuencia = secuencia;
    inputLog = {
        ...inputLog,
    };
    const tipoLog = error ? "log_error" : "log_negocio"
    await this.tracesService.registerLog(inputLog, logChannel, "", "", "", tipoLog);
  }

}
