import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import {IConfiguracionGananciaPerdida, IRequestGananciaPerdida, IRespuestaConfiguracion} from "../../models";

@Injectable({
    providedIn: 'root',
})
export class TransversalService {
    
    private URL_BFF_TRANSVERSAL = environment.ENDPOINTS.BFF.BFF_TRANSVERSAL;

    constructor( private readonly http: HttpClient) {}
 
    public getInstrumentos(mercado: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http
                .get(`${this.URL_BFF_TRANSVERSAL}/instrumentos-corredora`, {
                    params: {
                      mercado: mercado,
                    },
                })
                .toPromise()
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public getGananciaPerdida(data: IRequestGananciaPerdida): Promise<IRespuestaConfiguracion> {
        const config = environment.ENDPOINTS.BFF.BFF_TRANSVERSAL;
        const params = new HttpParams()
            .append('rut', data.rut)
            .append('segmento', encodeURIComponent(data.segmento))
            .append('mandatoFirmado', String(data.mandatoFirmado))
        const endpoint = `${config}/ganancia-perdida/obtener-configuracion`;
        return new Promise((resolve, reject) => {
            this.http.get<IConfiguracionGananciaPerdida>(endpoint, {params}).toPromise().then((data) => {
                resolve(data.data);
            }).catch((error) => {
                console.error("ERROR AL OBTENER SALDOS DE RENTABILIDAD GANANCIA PERDIDA ", error.message)
                reject(error);
            });
        })

    }
   
}
