import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MenuItemModel } from '../../models/menu-item.model';
import { ExecutiveService } from '../executive/executive.service';
import { WealthPartnersService } from '../wealth-partners/wealth-partners.service';
import { FeatureFlagsService } from '../feature-flags/feature-flags.service';

@Injectable({
  providedIn: 'root',
})
export class SidebarMenuService {
  private menuItemsSubject = new BehaviorSubject<MenuItemModel[]>([]);

  public readonly menuItems$ = this.menuItemsSubject.asObservable();

  constructor(private executiveService: ExecutiveService,
    private wealthPartnerService: WealthPartnersService) {}

  initializeMenu() {
    const isSupervisor: boolean = this.executiveService.executiveModel.executiveProfile.isSupervisor ?? false;
    const isBoss: boolean = this.executiveService.executiveModel.executiveProfile.isBoss ?? false;
    const isNotBroker: boolean = this.executiveService.executiveModel.executiveProfile.isBroker ?? false;
    const sesionConfiguracionMenu = sessionStorage.getItem('configuracionVariables');
    const confMenu = JSON.parse(sesionConfiguracionMenu);   
    this.menuItemsSubject.next([
      {
        icon: isSupervisor || isBoss ? 'groups' : 'contacts',
        text: isSupervisor || isBoss? 'Equipo de trabajo' : 'Cartera Clientes',
        iconType: 'outlined',
        url: '/main/client-portfolio',
        show: this.executiveService.executiveModel.executiveProfile.ownCustomers === true,
      },
      {
        icon: 'search',
        text: 'Buscador clientes',
        iconType: 'outlined',
        url: '/main/client-search',
        show: this.executiveService.executiveModel.executiveProfile.ownCustomers === false,
      },
      {
        icon: 'person_search',
        text: 'Cumplimiento',
        iconType: 'outlined',
        url: '/main/cumplimiento',
        show: true,
      },
      {
        icon: 'lock',
        iconType: 'outlined',
        text: 'Autorización SURA PASS',
        url: '/main/surapass/surapass-transacciones',
        show: true,
      },
      {
        icon: 'assignment',
        text: 'Fichas y Reglamentos',
        iconType: 'outlined',
        show: true,
        url: 'https://inversiones.sura.cl/sura/Paginas/Fondos-mutuos-fichas-y-reglamentos-.aspx',
        blank: true,
      },
      {
        icon: 'post_add',
        text: 'Formularios y Plantillas',
        show: !isNotBroker,
        url: 'https://gruposurachile.sharepoint.com/sites/Intranet/VPGerencia/Productos/Corredora/Paginas/Formularios-y-Contratos.aspx',
        blank: true,
      },
      {
        text: 'Accesos directos',
        subtitle: '(Requieren Red SURA o VPN)',
        children: [
          {
            text: 'GUC',
            icon: 'vpn_key',
            iconType: 'outlined',
            url: 'http://gucprod.sura.cl/',
            blank: true,
            show:isNotBroker,
          },
          {
            text: 'ACIC',
            icon: 'group',
            iconType: 'outlined',
            url: 'http://acicwmweb/Operativo/AsignacionClaveAleatoria.aspx',
            blank: true,
            show: isNotBroker,
          },
          {
            text: 'VISUAL TIME',
            icon: 'verified_user',
            iconType: 'outlined',
            url: 'http://visualtimeindi/vtime/visualtime/visualtime.htm',
            blank: true,
            show: isNotBroker,
          },
          {
            text: 'BiZNET',
            icon: 'folder',
            iconType: 'outlined',
            url: 'http://biznet/aspx/Navegacion.aspx',
            blank: true,
            show: isNotBroker,
          },
          {
            text: 'Paneles comerciales',
            icon: 'table_chart',
            iconType: 'outlined',
            url: 'https://app.powerbi.com/groups/350cafcb-a7c6-4475-b9f7-33c609960f20/reports/c270ca5e-2611-4a03-b4e8-8e63f29952d2/ReportSection58108f998d106863dafa?experience=power-bi',
            blank: true,
            show: isNotBroker,
          },
          {
            text: 'Portfolio builder',
            icon: 'business_center',
            iconType: 'outlined',
            url: 'https://portfoliobuilder.sura.cl/Home/Index/',
            blank: true,
            show: isNotBroker,
          },
          {
            text: 'Productos e inversión',
            icon: 'analytics',
            iconType: 'outlined',
            url: 'https://gruposurachile.sharepoint.com/sites/Intranet/VPGerencia/Productos/Paginas/Home-Productos.aspx',
            blank: true,
            show: isNotBroker,
          },
          {
            text: 'Soporte aplicaciones',
            icon: 'help_outline',
            iconType: 'outlined',
            url: 'http://soporteaplicaciones/Requerimientos/Requerimiento/NuevoRequerimiento',
            blank: true,
            show: isNotBroker
          },
          {
            text: 'RYQ Corporativo',
            icon: 'report_gmailerrorred',
            iconType: 'outlined',
            url: 'http://ryqcorporativo/inicio.ASPX',
            blank: true,
            show: isNotBroker
          },
          {
            text: 'PowerApps',
            icon: 'apps',
            iconType: 'outlined',
            url: 'https://apps.powerapps.com/play/e/default-2cb4d124-c9ed-48cc-aefd-228cd153e897/a/e4640ee6-2c57-41e6-a56a-f4a1ee6ac154?tenantId=2cb4d124-c9ed-48cc-aefd-228cd153e897&hint=8216ae40-bb1c-400a-883d-974434e9deb7&sourcetime=1720539058077#',
            blank: true,
            show: isNotBroker
          },
          {
            icon: 'post_add',
            text: confMenu.variables.TEXTO_MENU_WEALTH_PARTNERS,
            url: null,
            show: !(isNotBroker && confMenu.flags.HABILITAR_BOTON_WEALTH_PARTNERS),
            blank: true,
            isFnAction: true,
            fnAction: () => { 
              this.wealthPartnerService.irALandingWealthPartners();
            }
          },
          {
            text: confMenu.variables.TEXTO_PANEL_INDICADORES_WP,
            icon: 'table_chart',
            iconType: 'outlined',
            url: confMenu.variables.URL_PANEL_INDICADORES_WP,
            blank: true,
            show: !(isNotBroker && confMenu.flags.HABILITAR_BOTON_PANELES_COMERCIALES_WP),
          },
        ],
      },
      {
        text: 'Mantente informado',
        children: [
          {
            text: 'Noticias',
            icon: 'description',
            iconType: 'outlined',
            url: 'https://gruposurachile.sharepoint.com/sites/SitioWebINVERSIONESSURA',
            blank: true,
            show: isNotBroker,
          },
          {
            text: 'Tutoriales',
            icon: 'chrome_reader_mode',
            iconType: 'outlined',
            url:
              'https://gruposurachile.sharepoint.com/sites/SitioWebINVERSIONESSURA/Documentos compartidos/Forms/AllItems.aspx',
            blank: true,
            show: isNotBroker,
          },
          {
            text: 'Somos comerciales',
            icon: 'local_library',
            iconType: 'outlined',
            url:
              'https://gruposurachile.sharepoint.com/sites/Intranet/VPGerencia/Somos-Comerciales/Paginas/Somos-Comerciales.aspx',
            blank: true,
          }
        ],
      },
      {
        text: 'Información de mercado',
        children: [
          {
            text: 'Informes de Recomendación',
            icon: 'insert_chart_outlined',
            iconType: 'outlined',
            url: 'https://inversiones.sura.cl/nosotros/Paginas/informes-de-recomendacion.aspx',
            blank: true,
          },
          {
            text: 'Mercado financiero al día',
            icon: 'area_chart',
            iconType: 'outlined',
            url: 'https://inversiones.sura.cl/Inversiones/Paginas/informe-diario.aspx',
            blank: true,
          },
          {
            text: 'Podcast Inversiones SURA',
            icon: 'podcasts',
            iconType: 'outlined',
            url: 'https://inversiones.sura.cl/Inversiones/Paginas/podcast-sobre-asesoria-2020.aspx',
            blank: true,
          },
        ],
      },
    ]);
  }

  destroy() {
    this.menuItemsSubject.next(null);
  }
}
