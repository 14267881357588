import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { ContactInfo, DataClientModel, Product } from '../../models/client-summary/data-client.model';
import { rutFormat } from 'rut-helpers';
import { BusinessLineType, ClientRequestService } from '../client-request/client-request.service';
import { ClientSiteRequestModel } from '../../models/client-summary/client-data.model';
import { ClientUrl } from '@environment';
import { LoadingService } from '../../../shared/services/loading/loading.service';
import { ProfitabilityItem } from '../../models/client-summary/profitability.model';
import { getInitialsFromName, toTitleCase } from '../../../shared/helpers/utils';
import { ClientBalanceModel } from '../../models/client-summary/client-summary.model';
import { ExecutiveService } from '../../../shared/services/executive/executive.service';
import { messageType } from '../../../shared/components/user-message/user-message.component';
import { AppService } from '../../../shared/services/app/app.service';
import { DialogService } from 'src/app/shared/services';
import { VisualizadorClientesComponent } from '../../components/visualizador-clientes/visualizador-clientes.component';
import { XAuthService } from 'src/app/shared/services/xauth/xauth.service';
import * as moment from 'moment';

@Injectable()
export class ClientService {
    public searchResult = new BehaviorSubject<UserMessage>(null);

    private readonly productsMap = new Map<string, string>([
        ['AFP', 'AFP'],
        ['FFMM', 'FFMM'],
        ['Vida', 'INSURANCES'],
        ['Corredora', 'CORREDOR_BOLSA'],
        // ['RIA', 'RIA'],
    ]);

    constructor(private xAuthService:XAuthService, private clientRequestService: ClientRequestService, private loadingService: LoadingService, private executive: ExecutiveService, private appService: AppService, private dialogService: DialogService) { }

    getDataClient(): Observable<DataClientModel> {
        return combineLatest([
            this.clientRequestService.clientData.asObservable(),
            this.clientRequestService.clientAccionInversion,
            this.clientRequestService.clientBalances,
            this.clientRequestService.clientExecutive,
            this.clientRequestService.companyInfo,
            this.clientRequestService.signMandate,
            this.clientRequestService.personInfo,
            this.clientRequestService.documents,
            this.clientRequestService.statusPassword,
            this.clientRequestService.lastSesion,
        ]).pipe(
            filter(([data]) => data !== null),
            map(([data, accionInversion, balances, clientExecutive, companyInfo, signMandate, personInfo, documents, statusPass, ultimaSesion]) => {
                const products = this.getExistingProducts(balances);
                const profileType = accionInversion?.perfilInversionista?.Perfil_Prev || 'Sin perfil';
                const fechaPrev = accionInversion?.perfilInversionista?.fecha_Prev;
                const profileDate = fechaPrev ? moment(accionInversion?.perfilInversionista?.fecha_Prev, 'MM/DD/YYYY hh:mm:ss A').format('DD/MM/YYYY HH:mm:ss A') : null;                
                const executiveName = this.getExecutiveName(clientExecutive?.NombreEjecutivo, clientExecutive?.ApellidoPaternoEjecutivo);
                const companyInfoData: ContactInfo[] = this.getCompanyInfo(companyInfo);
                let lastSession = '';
                if (ultimaSesion && ultimaSesion.length > 0) {
                    lastSession = ultimaSesion[0].fecha;
                }
                // * If the object is empty, there was an error in the loadData call in client-request.service
                const isPersonInfoEmpty = !Object.keys(personInfo || {})?.length;
                const emailClient = isPersonInfoEmpty ? 'Teléfono no disponible' : personInfo?.email;
                const phoneClient = isPersonInfoEmpty ? 'Email no disponible' : personInfo?.telefono;

                const datosPortafolio = accionInversion?.poseeFichacliente?.dataOptimus?.SocioNegocio[0]?.DatosPortafolio;
                const IdTipoBloqueo = accionInversion?.poseeFichacliente?.dataOptimusNueva?.SocioNegocio[0]?.IdTipoBloqueo;
                const FechaDefuncion = accionInversion?.poseeFichacliente?.dataOptimusNueva?.SocioNegocio[0]?.FechaDefuncion;

                const passObj = {
                    glosaTipoClave: '',
                    codigoEstadoClave: '',
                    glosaEstadoClave: ''
                }

                statusPass = statusPass ?
                    statusPass.map(pass => {
                        pass.glosaTipoClave = pass.glosaTipoClave.replace('CLAVE', '');
                        return pass;
                    }) : [passObj, passObj];

                return this.formatResponseDataClient({
                    data,
                    accionInversion,
                    clientExecutive,
                    documents,
                    statusPass,
                    products,
                    profileType,
                    executiveName,
                    companyInfoData,
                    signMandate,
                    lastSession,
                    isPersonInfoEmpty,
                    emailClient,
                    phoneClient,
                    datosPortafolio,
                    IdTipoBloqueo,
                    FechaDefuncion,
                    profileDate,
                })
            }),
        );
    }

    public formatResponseDataClient(allData: any): DataClientModel {
        const { data,
            accionInversion,
            clientExecutive,
            documents,
            statusPass,
            products,
            profileType,
            executiveName,
            companyInfoData,
            signMandate,
            lastSession,
            IdTipoBloqueo,
            FechaDefuncion,
            isPersonInfoEmpty,
            emailClient,
            phoneClient,
            datosPortafolio,
            profileDate
        } = allData;
        return {
            rut: rutFormat(`${data.RutCliente}-${data.DigitoVerificador}`),
            name: data.NombreCliente,
            initials: getInitialsFromName(data.NombreCliente),
            lastSesion: lastSession,
            IdTipoBloqueo: IdTipoBloqueo,
            FechaDefuncion: FechaDefuncion,
            clientInfo: [
                {
                    icon: 'mail',
                    text: emailClient,
                    href: emailClient && !isPersonInfoEmpty ? `mailTo:${emailClient}` : null,
                },
                {
                    icon: 'phone',
                    text: phoneClient,
                    href: phoneClient && !isPersonInfoEmpty ? `tel:${phoneClient}` : null,
                },
            ],
            profile: {
                type: profileType,
                state: accionInversion?.perfilInversionista?.HEADER === '00',
                error: accionInversion === null,
                date: profileDate
            },
            segment: clientExecutive === null ? 'No disponible' : data.DescripcionSegmento ?? 'Sin segmento',
            companyInfo: companyInfoData,
            executiveName,
            products,
            documentation: [
                {
                    key: 'ticketClient',
                    label: 'Ficha Cliente',
                    isOkey: accionInversion?.poseeFichacliente?.tieneFicha,
                    result: accionInversion?.poseeFichacliente?.tieneFicha ? 'Actualizado' : 'Pendiente',
                },
                {
                    key: 'ccg',
                    label: 'CCG (380)',
                    isOkey: documents?.ccg380,
                    result: documents?.ccg380 ? 'Actualizado' : 'Pendiente',
                },
                {
                    key: 'mandateAfp',
                    label: 'Mandato AFP',
                    isOkey: signMandate?.mandatoSuscrito,
                    result: signMandate?.mandatoSuscrito ? 'Firmado' : 'Sin Firmar',
                    isStateNoMandato: signMandate?.estado == 'NoMandato' ? false : true
                },
                {
                    key: 'planApv',
                    label: 'Plan APV',
                    isOkey: documents?.planApv,
                    result: documents?.planApv ? 'Actualizado' : 'Sin Firmar',
                },
            ],
            statusPaswword: statusPass,
            datosPortafolio
        };
    }

    private getCompanyInfo(companyInfo): ContactInfo[] {
        sessionStorage.removeItem('rutRepresentanteLegal');
        if (companyInfo) {
            const telefonoEmpresa = companyInfo.telefonosEmpresa.telefonoEmpresa
            const telefono = !Array.isArray(telefonoEmpresa) ? telefonoEmpresa : telefonoEmpresa[0];
            const emailEmpresa = companyInfo.emailsEmpresa?.emailEmpresa
            const email = !Array.isArray(emailEmpresa) ? emailEmpresa?.correo : this.obtenerCorreoEmpresa(emailEmpresa);
            let companyPhone = null;
            if (telefono) {
                companyPhone = telefonoEmpresa.codigoPais + telefonoEmpresa.codigoArea + telefonoEmpresa.numero;
            }
            const rutRepresentanteLegal = companyInfo?.representanteLegal?.rut
            if (rutRepresentanteLegal?.dv && rutRepresentanteLegal?.numero) {
                const {dv, numero} = rutRepresentanteLegal ?? {};
                sessionStorage.setItem('rutRepresentanteLegal', `${numero}-${dv}`);
            }
            return [
                {
                    text: companyInfo.representanteLegal.nombre + ' ' + companyInfo.apellidoPaterno + ' ' + companyInfo.apellidoMaterno,
                    icon: 'person',
                },
                {
                    text: rutFormat(`${companyInfo.representanteLegal.rut.numero}-${companyInfo.representanteLegal.rut.dv}`),
                    icon: 'folder_shared',
                },
                {
                    text: email,
                    icon: 'mail',
                    href: email ? `mailTo:${email}` : null,
                },
                {
                    text: companyPhone,
                    icon: 'phone',
                    href: companyPhone ? `tel:${companyPhone}` : null,
                },
            ];
        }
        return null;
    }

    private getExecutiveName(nombre: string, apellido: string): string {
        if (typeof nombre === 'string' && typeof apellido === 'string') {
            return `${nombre} ${apellido}`;
        }
        return 'Sin información';
    }

    private getExistingProducts(balances: ClientBalanceModel[] | null): Product[] {
        return Array.from(this.productsMap.keys()).map((p) => {
            const found = balances?.find((item) => item.type === this.productsMap.get(p));
            return {
                displayName: p,
                exists: !!found,
                error: balances === null,
            };
        });
    }

    async goToSiteClient(client: Client, rutaCliente: string, prod?: any) {
        this.loadingService.showLoading();

        const executiveModel = this.executive.executiveModel;
        let clientProfile = "Sin Perfil";
        this.getDataClient().pipe(take(1)).subscribe((data) => {
            clientProfile = data.profile.type;
        }).unsubscribe();

        const payload: ClientSiteRequestModel = {
            FechaNacimiento: null,
            RutCliente: client.rut,
            Nombre: client.nombre,
            NombreEjecutivo: sessionStorage.getItem('NombreEjecutivoAsesor') ?? executiveModel.executiveName,
            rutEjecutivoAsesor: executiveModel.executiveRut,
            ApellidoPaterno: "",
            ApellidoMaterno: "",
            TipoUsuario: 'Ejecutivo',
            MandatoSuscrito: false,
            TipoAsesor: executiveModel.executiveProfile.profileName,
            PerfilAsesor: executiveModel.executiveProfile,
            sesion: sessionStorage.getItem('sesionAsesor') ?? 'S/I',
            cuentaAD: sessionStorage.getItem('cuentaAD')
        };

        const executiveUsername = payload.NombreEjecutivo;
        this.xAuthService.generarXToken(payload).then((resp:any) => {
            const { token, xtoken } = resp?.data;
            const realToken = xtoken ?? token;
            let locationUrl = `${ClientUrl}/auth?t=${realToken}&ruta=${rutaCliente}&perfilCliente=${clientProfile}&rutEjecutivoAsesor=${payload.rutEjecutivoAsesor}&executiveUsername=${executiveUsername}`;
            if (prod !== undefined) {
                const extras = `​&name=${prod.codigoCb}​&amount=${prod.amount}&type=${prod.type}&prevision=${prod.prevision}&order=${prod.order}`;
                locationUrl = `${locationUrl}${extras}`
            } else {
                locationUrl = `${ClientUrl}/auth?t=${realToken}&ruta=${rutaCliente}&perfilCliente=${clientProfile}&rutEjecutivoAsesor=${payload.rutEjecutivoAsesor}&executiveUsername=${executiveUsername}`;
            }
            this.openVisualizadorCliente(locationUrl, payload, clientProfile, realToken);
            this.loadingService.hideLoading();

        });
    }

    openVisualizadorCliente(urlAuth, datosCliente, clienteProfile, xtoken = null) {
        const config = {
            disableClose: true,
            maxWidth: '95%',
            width: '95%',
            height: '95%',
            data: { urlAuth, datosCliente, clienteProfile, xtoken },
        };

        const value = this.dialogService.openDialog(VisualizadorClientesComponent, config)
    }

    getTransitBalance() {
        return this.clientRequestService.clientBalances.pipe(
            map((balance) => {
                return balance?.filter((data) => data?.amountInTransit > 0);
            }),
        );
    }

    getProfitability(): Observable<ProfitabilityItem[]> {
        return this.clientRequestService.profitability?.pipe(
            map((data) => {
                if (data?.rentabilidad?.length > 0) {
                    const rentabilidad = data.rentabilidad;
                    const [ytd, monthly, lastThreeMonth, lastTwelveMonth] = rentabilidad;
                    const monthName = data.monthName ?? 'mensual';

                    return [
                        {
                            label: toTitleCase(monthName),
                            balances: monthly?.Monto,
                            rentability: monthly?.Porciento,
                        },
                        {
                            label: 'Últ. 3 meses',
                            balances: lastThreeMonth?.Monto,
                            rentability: lastThreeMonth?.Porciento,
                        },
                        {
                            label: 'Últ. 12 meses',
                            balances: lastTwelveMonth?.Monto,
                            rentability: lastTwelveMonth?.Porciento,
                        },
                        {
                            label: 'YTD',
                            balances: ytd?.Monto,
                            rentability: ytd?.Porciento,
                        },
                    ];
                }
                return [];
            }),
        );
    }

    async getProductDetail(params): Promise<any> {
        return this.clientRequestService.getProductDetail(params);
    }

    async getCertificateMonth(rut: string, fecha: string, lineaNegocio: BusinessLineType): Promise<any> {
        return this.clientRequestService.getCertificateMonth(rut, fecha, lineaNegocio);
    }

    obtenerCorreoEmpresa(emailEmpresa) {
        let correo = '';
        const emailEncontrado = emailEmpresa?.find(data => data.estadoEmail === '1');
        if (emailEncontrado) {
            correo = emailEncontrado?.correo;
        }
        return correo;
    }

    }

export interface UserMessage {
    isError?: boolean;
    useTitle?: string;
    useMessage?: string;
    mainMessage?: string;
    userMessageType?: messageType;
}

export interface Client {
    rut: string;
    nombre: string;
    apellidoPaterno: string;
    apellidoMaterno: string;
}
