import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoadingService } from 'src/app/shared/services';
import { ClientRequestService } from '../../../../../services/client-request/client-request.service';
import * as utils from 'src/app/shared/helpers/utils';
import { TracesService } from 'src/app/core/services/traces/traces.service';
import { AliasInputLogType, InputLog } from 'src/app/features/models/log/InputLog';
import { Status } from 'src/app/features/models/log/status';
import * as moment from 'moment';
import { ConfigIndexLogStash } from 'src/app/shared/models';
@Component({
    selector: 'app-questions-profile',
    templateUrl: './questions-profile.component.html',
    styleUrls: ['./questions-profile.component.scss'],
})
export class QuestionsProfileComponent implements OnInit {
    @Input() visibled: boolean;
    @Input() selected: boolean;
    @Input() errorSelectEmit: boolean;
    @Input() dataResponse: any;
    @Output() disabled = new EventEmitter<boolean>();
    @Output() itemSelect = new EventEmitter<boolean>();
    @Output() errorSelect = new EventEmitter<boolean>();
    @Output() alertMessageReturn = new EventEmitter<boolean>();
    @Output() checkAlertMessage = new EventEmitter<boolean>();
    @Output() resumeActive = new EventEmitter<string>();
    @Output() responseDataFile = new EventEmitter<any>();

    @Input() disableRipple: boolean;
    @Input() statusContinue: boolean;
    @Input() result: object;
    @Input() itemResponse: object;
    @Input() alertStatus: boolean;
    @Input() selectItem: any;
    @Input() itemsGroupSelect: any;

    response: any;
    questions: any;
    pressButtomContinue: boolean;
    favoriteSeason = [];
    classSelected: string;
    responseFileData: any;
    messageAlertFile: boolean;
    data: any;
    questionsPlane: any[] = [];

    clientName: string;
    description: string;

    statusTitle: boolean;

    title: string = '';
    title2: string = 'Para finalizar, elige el archivo con la autorización del cliente';
    subtitle: string = 'Los formatos permitidos son: .eml, .png, .jpg, .gif, .ppt, .doc, .pdf, .xls y su peso máximo es de 5MB';

    pagina: number;

    constructor(
        private clientRequestService: ClientRequestService, 
        private loadingService: LoadingService,
        private tracesService: TracesService,
        ) {
        this.loadingService.showLoading();
    }

    optionSelect(event) {
        event.page = this.pagina;
        this.itemSelect.emit(event);
        this.checkOptionSelected(this.pagina);
    }

    checkOptionSelected(page) {
        const statusPage = this.itemsGroupSelect.find((x) => x.page === page);
        if (statusPage !== undefined) {
            this.pressButtomContinue = statusPage;
        }
    }

    changeDisabled(status: boolean) {
        this.visibled = status;
        this.disabled.emit(true);
        this.checkAlertMessage.emit(false);
        this.pressButtomContinue = false;
    }

    async ngOnInit() {
        this.loadingService.showLoading();
        let asesor = sessionStorage.getItem('dataContentful');
        const executiveUsername = sessionStorage.getItem('executiveUsername');
        let dataAsesor = JSON.parse(asesor);
        let executiveRut = dataAsesor?.executiveRut ?? '';
        let executiveName = dataAsesor?.executiveName ?? '';
        const infoParam = {
            lineaNegocio: "WMAN",
            codigoTransaccion: '1',
            descripcionTransaccion: 'Perfil Inversionista',
            folioTransaccion: '0',
            codigoCanalIngreso: "1",
            descripcionCanalIngreso: "Sura Asesor",
            codigoUsuarioResponsable: executiveUsername,
            rutUsuarioResponsable: executiveRut.split("-")[0],
            nombreUsuarioResponsable: executiveName,
            numeroProducto: "0",
        };

        this.data = utils.formatParamInfoContentManager(infoParam);

        this.response = await this.clientRequestService.getInvestorProfileQuestions();
        this.loadingService.hideLoading();
        this.questions = this.response.pregunta;
        this.checkAlertMessage.emit(false);
        this.pressButtomContinue = false;
    }

    async simulationProfile(event) {
        this.questionsPlane = [];
        this.dataResponse.respuestas.forEach((element) => {
            this.questionsPlane.push({
                numeroInternoPregunta: element.numeroInternoPregunta,
                identificadorAlternativa: element.identificadorAlternativa,
            });
        });
        try {
            if (event === true) {
                this.loadingService.showLoading();
                let response = await this.clientRequestService.postObtenerPerfilSimulacion({ respuestas: this.questionsPlane });
                this.loadingService.hideLoading();
                this.logData({
                    Categoria: 'Perfil Inversionista',
                    Operacion: 'Perfil Inversionista/Actualizar Perfil Paso 2',
                    Metodo: 'simulationProfile()',
                    eventoLog: `calculando perfil ok`,
                    status: Status.OK,
                    Secuencia: 5,
                    EntradaLog: {
                        info: event.info,
                        resultado: this.response
                    },
                }, 'log_negocio');
                response.salida.perfiles.perfil.forEach((element) => {
                    if (element.tipoRegimen === 'Inversionista') {
                        const description = element.descripcion;
                        let clientData = JSON.parse(sessionStorage.getItem('clientData'));
                        const descriptionToUpperCase = description.toUpperCase();
                        this.clientName = clientData.name;
                        this.description = descriptionToUpperCase;
                    }
                });
            }
        } catch (error) {
            this.logData({
                Categoria: 'Perfil Inversionista',
                Operacion: 'Perfil Inversionista/Actualizar Perfil Paso 2',
                Metodo: 'simulationProfile()',
                eventoLog: `calculando perfil ERROR`,
                status: Status.NOK,
                Secuencia: 5,
                EntradaLog: {
                    info: event.info,
                    error
                },
            }, 'log_error');
            this.loadingService.hideLoading();
            return error;
        }
    }

    checkValue(event) {
        this.pressButtomContinue = event;
    }

    checkAlert(event) {
        this.checkAlertMessage.emit(event);
    }

    activeEventResume(event) {
        this.resumeActive.emit(event);
    }

    seleccionPagina(event) {
        this.pagina = event + 1;
    }

    messageAlert(event) {
        this.messageAlertFile = event;
    }

    responseFile(event) {
        this.responseFileData = event;
        this.responseDataFile.emit(this.responseFileData);
        this.logData({
            Categoria: 'Perfil Inversionista',
            Operacion: 'Perfil Inversionista/Actualizar Perfil Paso 2',
            Metodo: 'simulationProfile(event)',
            eventoLog: `Flujo subida de archivo de autorización CARGADO`,
            status: Status.OK,
            Secuencia: 4,
            EntradaLog: event.info,
        }, 'log_negocio');
    }

    async logData(log: Partial<InputLog> & Pick<InputLog, 'Categoria' | 'Metodo' | 'Operacion' | 'status'>, indexLogStash: ConfigIndexLogStash) {
        try {
            const { EntradaLog, ...logData } = log;

            let inputLog: InputLog = new InputLog();
            inputLog.DetalleAccionExtra = {};
            inputLog.Categoria = log.Categoria;
            inputLog.Operacion = log.Operacion;
            inputLog.EntradaLog = {
                rut: JSON.parse(sessionStorage.getItem('dataContentful')).executiveRut,
                fechaHoraOp: moment(new Date()).format('DD/MM/YYYY HH:mm:ss'),
                ...EntradaLog,
            };
            inputLog.Salida = '-';
            inputLog.Tipo_sesion = 'ASESOR';
            inputLog = {
                ...inputLog,
                ...logData,
            };
            await this.tracesService.registerLog(inputLog, 'NWSA_ASESOR', '', '', '', indexLogStash);
        } catch (error) {
            console.error('error: ', error);
        }
    }
}
