import { Injectable } from '@angular/core';
import { ClientRequestService } from '../../../features/services';
import { IIndicadoresCliente } from './indicador-cliente.interface';

@Injectable({
  providedIn: 'root'
})
export class IndicadorClienteService {

  
  indicadoresCliente: IIndicadoresCliente = JSON.parse(sessionStorage.getItem('indicadoresCliente') || '{}');

  constructor(
    clientRequestService: ClientRequestService
  ) {
    clientRequestService.clientAccionInversion.subscribe((resp) => {
      this.setIndicadoresCliente(resp)
    });
  }

  setIndicadoresCliente(resp: any){
    let dataOptimus = (resp as any)?.poseeFichacliente?.dataOptimus;
    try {
      if(dataOptimus?.ContadorErrores === 0){
        const dataFichaCliente = dataOptimus?.SocioNegocio[0];
        const fichaCliente: any = this.searchIndicadorCliente(dataFichaCliente?.IndicadorCliente, 'FICHACL');
        const cedula: any = this.searchIndicadorCliente(dataFichaCliente?.IndicadorCliente, 'FOTOCOPIA');
        this.indicadoresCliente = {
          tieneContratoFicha: this.validaIndicadorCliente(fichaCliente?.ValorIndicador),
          tieneDocumentacion: this.validaIndicadorCliente(cedula?.ValorIndicador),
          contract380: resp?.contract380,
          perfilInversionista: resp?.perfilInversionista.HEADER,
          conDatosEnSocioNegocio: dataFichaCliente != null
        }
        sessionStorage.setItem("indicadoresCliente", JSON.stringify(this.indicadoresCliente));
      }
    } catch (error) {
      console.log(error);
    }
  }

  searchIndicadorCliente(indicadorCliente, codigoTipoIndicador){
    try {
      return indicadorCliente?.find(indicador => indicador?.CodigoTipoIndicador === codigoTipoIndicador);
    } catch (error) {
      console.log(error);
    }
  }

  validaIndicadorCliente(valorIndicador){
    return valorIndicador === 'S';
  }


}
