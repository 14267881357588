import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { rutValidator } from '../../../validators/rut.validator';
import { ClientSearchModel, ClientSearchService } from '../../../services/client-search/client-search.service';
import { ClientRequestService } from '../../../../features/services/client-request/client-request.service';
import { ClientService, UserMessage } from "../../../../features/services/client/client.service";
import { LoadingService } from '../../../services/loading/loading.service';
import * as rutHelpers from 'rut-helpers';
import { RuteroCMS } from 'src/app/shared/models/cms.model';
import { InputLog } from 'src/app/features/models/log/InputLog';
import { Status } from 'src/app/features/models/log/status';
import * as moment from 'moment';
import { ConfigIndexLogStash } from 'src/app/shared/models';
import { TracesService } from 'src/app/core/services/traces/traces.service';
import { UtilsService } from 'src/app/shared/services';

@Component({
  selector: 'app-navbar-search-customers',
  templateUrl: './navbar-search-customers.component.html',
  styleUrls: ['./navbar-search-customers.component.scss'],
})
export class NavbarSearchCustomersComponent implements OnInit {
  clientSearchInput = new FormControl('', {
    validators: [rutValidator],
    updateOn: 'change',
  });
  showSearch = false;
  listClientsAGF: RuteroCMS;

  constructor(
    private router: Router,
    private clientSearchService: ClientSearchService,
    private clientRequestService: ClientRequestService,
    private clientService: ClientService,
    private loadingService: LoadingService,
    private tracesService: TracesService,
    private utilsService: UtilsService
  ) {}

  ngOnInit() {
    this.clientSearchService.clientsAFG.subscribe( async (data:RuteroCMS) => {
      this.listClientsAGF = data;
    })
  }

  async searchClient(value?) {
    this.utilsService.limpiarVariablesPershing();
    const dni = typeof value !== 'undefined' ? value : this.clientSearchInput.value;
    const logtemp: Partial<InputLog> & Pick<InputLog, "Categoria" | "Metodo" | "Operacion" | "status"> = {
      Categoria: "Resumen Cliente",
      Operacion: "Resumen Cliente",
      Metodo: "searchClient()",
      eventoLog: "click boton buscador en resumen",
      status: Status.OK,
      Secuencia: 0,
      EntradaLog: {
        accion: 'click boton buscador en resumen redireccionar',
      },
    };
    this.clientSearchInput.setValue(dni);
    this.clientSearchInput.updateValueAndValidity();
    let userMessage: UserMessage;
    if (this.clientSearchInput.valid && rutHelpers.rutValidate(dni)) {
      this.loadingService.showLoading(false);
      this.clientRequestService.clientData.next(null);
      this.clientSearchService
        .clientSearch(dni)
        .then(async (clientSearchModel: ClientSearchModel) => {
          if (!clientSearchModel.dataSigned) {
            userMessage = {
              isError: true,
              useMessage: 'Si el cliente es de AFP Capital debe firmar el mandato de consolidación de saldos',
              useTitle: 'Mandato AFP sin firmar:',
              userMessageType: "error"
            }
            this.clientService.searchResult.next(userMessage);

            await this.router.navigate(['/main/client-search']);
            this.clientSearchInput.setValue('');
            this.loadingService.hideLoading();
            return;
          }
          this.clientRequestService.clientData.next(clientSearchModel.dataClient);

          this.logData(
            logtemp,
            "log_negocio",
          );
          // Todo: Este navigate se hace para forzar el cambio de ruta, tal vez buscar una mejor solución
          await this.router.navigate(['./'], {
            skipLocationChange: true,
          });

          await this.router.navigate(['/main/client-search/summary', clientSearchModel.dni]);
          this.clientSearchInput.setValue('');
          this.toggleSearch();
        })
        .catch(async (error) => {
          logtemp.status = Status.NOK;
          logtemp.EntradaLog.accion = 'click boton buscador en resumen: Lo sentimos, no hemos encontrado un resultado';
          this.logData(
            logtemp,
            "log_error",
          );
          if (error.code === 'notFound') {
            this.clientService.searchResult.next({
              isError: false,
              mainMessage: `Lo sentimos, no hemos encontrado un resultado para <br><b>${dni}</b><br> Inténtalo nuevamente`,
            });
            await this.router.navigate(['/main/client-search']);
          }
          this.clientSearchInput.setValue('');
          this.loadingService.hideLoading();
        });
    }
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }
  
  async logData(log: Partial<InputLog> & Pick<InputLog, "Categoria" | "Metodo" | "Operacion" | "status">, indexLogStash: ConfigIndexLogStash) {
    try {
      const { EntradaLog, ...logData } = log;

      let inputLog: InputLog = new InputLog();
      inputLog.DetalleAccionExtra = {};
      inputLog.Categoria = log.Categoria;
      inputLog.Operacion = log.Operacion;
      inputLog.EntradaLog = {
        rut: JSON.parse(sessionStorage.getItem('dataContentful')).executiveRut,
        fechaHoraOp: moment(new Date()).format("DD/MM/YYYY HH:mm:ss"),
        ...EntradaLog,
      };
      inputLog.Salida = "-";
      inputLog.Secuencia = 0;
      inputLog.Servicio = "";
      inputLog.Tipo_sesion = "ASESOR";
      inputLog.extra = {};

      inputLog = {
        ...inputLog,
        ...logData,
      };
      await this.tracesService.registerLog(inputLog, "NWSA_ASESOR", "", "", "", indexLogStash);
    } catch (error) {
      console.error("error: ", error);
    }
  }
}
